import { gsap } from "gsap";
//import { ScrollTrigger } from "gsap/scrollTrigger.js";
//import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
//gsap.registerPlugin(ScrollTrigger);
//gsap.registerPlugin(ScrollToPlugin);

$(function () {


    let we_scaleUpOnHover = (element, factor, duration) => {
        //Timeline
        var heroPsdToHtmlBoxHover = gsap.timeline({
					repeat: 0,
					repeatDelay: 1,
					paused: true,
				});

        //Timeline Tweens.
        heroPsdToHtmlBoxHover.to(element, {
            duration: duration,
            scale: factor,
        });

        $(element).hover(function () {
                // over
                heroPsdToHtmlBoxHover.play();
                
            }, function () {
                // out
                heroPsdToHtmlBoxHover.reverse();
            }
        );

    };
    we_scaleUpOnHover(".hero__design-to-code-container", 1.04, 0.8);
    we_scaleUpOnHover(".hero__wp-services-woocommerce", 1.02, 0.8);
    we_scaleUpOnHover(".hero__wp-services-wordpress", 1.02, 0.8);

});