//import "bootstrap";
import { Modal } from "bootstrap";
import { bp } from "../../../../../development/js-global/variables-constants/breakpoints";
$(function () {
	//WP PORTFOLIO

	//GET TEMPLATES DATA VIA AJAX
	$.ajax({
		url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
		type: "POST",
		//processData: true,
		/* contentType: false, */
		dataType: "html",
		//contentType: 'text/html',
		data: {
			action: "we_get_templates_data",
			security: ajax_objects.ajax_nonce,
		},
		//data: fd,
		success: function (data) {
			var response = JSON.parse(data);
			console.log(response.data);

			sessionStorage.setItem("weTemplatesData", JSON.stringify(response.data));

			console.log(sessionStorage.getItem("weTemplatesData"));
			console.log(JSON.parse(sessionStorage.getItem("weTemplatesData")));

			function weTransformSlugToName(str) {
				// Replace all hyphens with spaces
				let modifiedStr = str.replace(/-/g, ' ');
				// Capitalize the first letter of the first word
				modifiedStr = modifiedStr.charAt(0).toUpperCase() + modifiedStr.slice(1);
				return modifiedStr;
			}

			function weRenderPriceOnArchiveTemplates() {
				const formatterEur = new Intl.NumberFormat("hr-HR", {
					style: "currency",
					currency: "EUR",
					currencyDisplay: "code",
				});
				const formatterHrk = new Intl.NumberFormat("hr-HR", {
					style: "currency",
					currency: "HRK",
					currencyDisplay: "code",
				});
				var allTemplatesData = response.data;
				$.each(allTemplatesData, function (indexInArray, valueOfElement) {
					//console.log("valueOfElement");
					//console.log(valueOfElement);
					if (
						$('[data-id="' + valueOfElement.post_id + '"]').find(
							".zagrebweb-template-meta"
						).length == 0
					) {
						$(
							'[data-id="' + valueOfElement.post_id + '"] .template-meta'
						).append(
							`<div class="zagrebweb-template-meta mt--10" data-template-thumbnail-url="${
								valueOfElement.post_thumbnail_url
							}">
						<div class="zagrebweb-categories pb-10">
						<span class="zagrebweb-categories__single-template-id d-block">#${
							valueOfElement.post_id
						}</span>
						<span class="zagrebweb-categories__single-category">${
							valueOfElement.categories
						}</span>
						</div>`+
						/* <div class="zagrebweb-price py-10">
						<div class="zagrebweb-price__eur">
						
						</div>
						<div class="zagrebweb-price__eur">
						<span class="zagrebweb-price__from">od </span><span class="zagrebweb-price__nummerical">${formatterEur.format(
							Number(valueOfElement.displayed_price)
						)}</span>
						</div>
						<div class="zagrebweb-price__hrk d-none">
						<span class="zagrebweb-price__from">od </span><span class="zagrebweb-price__nummerical">${formatterHrk.format(
							Number(valueOfElement.displayed_price * 7.5345)
						)}</span>
						</div>
						</div> */
				`<div class="we-service-request-button-container">
				<div class="we-service-request-button-container__inner">
					<button type="button" class="we-service-request-button mb-20" data-we-service-name="pravne-usluge" data-we-service-title="Predložak '${weTransformSlugToName(valueOfElement.post_slug)}', Predložak ID '${valueOfElement.post_id}'" data-toggle="modal" data-target="#we-service-request-modal">
					<div class="we-service-request-button__inner">Zatraži ponudu</div>
					</button>
				</div>
				</div>
						</div>`
						);
					}
				});
			}
			setInterval(weRenderPriceOnArchiveTemplates, 1000);

			if (window.history.replaceState) {
				window.history.replaceState(null, null, window.location.href);
			}
		},
		error: function (data) {
			//var results = JSON.parse(data);
			console.log("ajax error");
			console.log(data);
			$(".we-loading").remove();

			if (window.history.replaceState) {
				window.history.replaceState(null, null, window.location.href);
			}
		},
	});
	//END GET TEMPLATES DATA VIA AJAX

	//GET TEMPLATES CATEGORIES
	$.ajax({
		url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
		type: "POST",
		//processData: true,
		/* contentType: false, */
		dataType: "html",
		//contentType: 'text/html',
		data: {
			action: "we_get_templates_category_terms_data",
			security: ajax_objects.ajax_nonce,
		},
		//data: fd,
		success: function (data) {
			var response = JSON.parse(data);
			//console.log(response.data);
			var allTemplatesCategoryTermsData = response.data;
			var allTemplatesCategoryTermsCount = [];
			$.each(
				allTemplatesCategoryTermsData,
				function (indexInArray, valueOfElement) {
					allTemplatesCategoryTermsCount.push(valueOfElement.count);
				}
			);
			var allNumberOfTemplates = allTemplatesCategoryTermsCount.reduce(
				(partialSum, a) => partialSum + a,
				0
			);
			function weRenderQuantityOnArchiveTemplatesCategoryTerms() {
				if (
					$('.astra-portfolio-categories a[data-group="all"]')
						.parent()
						.find(".zagrebweb-template-category-count").length == 0
				) {
					$('.astra-portfolio-categories a[data-group="all"]')
						.parent()
						.append(
							`<div class="zagrebweb-template-category-count">
						<div class="zagrebweb-template-category-count__number">
						<span>${allNumberOfTemplates}</span>
						</div>
						</div>`
						);
				}

				$.each(
					allTemplatesCategoryTermsData,
					function (indexInArray, valueOfElement) {
						//console.log(indexInArray);
						if (
							$(
								'.astra-portfolio-categories a[data-group="' +
									valueOfElement.term_id +
									'"]'
							)
								.parent()
								.find(".zagrebweb-template-category-count").length == 0
						) {
							$(
								'.astra-portfolio-categories a[data-group="' +
									valueOfElement.term_id +
									'"]'
							)
								.parent()
								.append(
									`<div class="zagrebweb-template-category-count">
						<div class="zagrebweb-template-category-count__number">
						<span>${valueOfElement.count}</span>
						</div>
						</div>`
								);
						}
					}
				);
			}
			setInterval(weRenderQuantityOnArchiveTemplatesCategoryTerms, 1000);

			if (window.history.replaceState) {
				window.history.replaceState(null, null, window.location.href);
			}
		},
		error: function (data) {
			//var results = JSON.parse(data);
			console.log("ajax error");
			console.log(data);
			$(".we-loading").remove();

			if (window.history.replaceState) {
				window.history.replaceState(null, null, window.location.href);
			}
		},
	});
	//END GET TEMPLATES CATEGORIES

	function renderWPPortfolioZagrebWebCTA() {
		/* if ($("#TB_title .zagrebweb-single-template-details-btn").length == 0) {
			$("#TB_title #TB_ajaxWindowTitle").after(
				`<a class="zagrebweb-single-template-details-btn" href="#"><span>UVJETI</span></a>
				<style>
				.zagrebweb-single-template-details-btn {
				    background: transparent;
					color: #fff;
					padding: 10px 25px;
					border-radius: 30px;
					font-weight: 700;
					border: 2px solid #fff;
					margin-left: 15px;
				}
				</style>
				`
			);
		} */
		if ($("#TB_title .zagrebweb-single-template-order-btn").length == 0) {
			var weSingleTemplateName;
			function handleElement() {
				const templateName = $('.we-template-name').text();
				console.log("Template Name:", templateName);
				// Do something with the text, e.g., display it or store it
				weSingleTemplateName = $(".we-template-name").text();

				console.log("weSingleTemplateName:" + weSingleTemplateName);
				if ($("#TB_title .zagrebweb-single-template-order-btn").length < 1) {
			$("#TB_title #TB_ajaxWindowTitle").after(
				`<a class="zagrebweb-single-template-order-btn" href="#" data-we-service-title="Predložak ${weSingleTemplateName}" data-toggle="modal" data-target="#we-service-request-modal"><span>Zatraži ponudu</span></a>
				<style>
				.zagrebweb-single-template-order-btn {
				    background: #4376a3;
					color: #fff;
					padding: 10px 25px;
					border-radius: 30px;
					font-weight: 700;
					border: 2px solid #fff;
				}
				</style>
				`
			);
			}
			}
		
			function observeElement(element) {
				// Create a new MutationObserver for the specific element
				const elementObserver = new MutationObserver((mutationsList, observer) => {
					for (const mutation of mutationsList) {
						if (mutation.type === 'characterData' || mutation.type === 'childList') {
							handleElement();
						}
					}
				});
		
				// Observe changes to the element's text content
				elementObserver.observe(element, { characterData: true, subtree: true, childList: true });
		
				// Handle the element being removed from the DOM
				elementObserver.observe(element.parentNode, { childList: true });
			}
		
			// Create a global observer for the entire document body
			const globalObserver = new MutationObserver((mutationsList, observer) => {
				for (const mutation of mutationsList) {
					if (mutation.type === 'childList') {
						$(mutation.addedNodes).each(function() {
							if ($(this).hasClass('we-template-name') || $(this).find('.we-template-name').length) {
								handleElement();
								observeElement(this.querySelector('.we-template-name') || this);
							}
						});
		
						$(mutation.removedNodes).each(function() {
							if ($(this).hasClass('we-template-name')) {
								console.log(".we-template-name element was removed.");
							}
						});
					}
				}
			});
		
			// Start observing the document body for additions and removals of child nodes
			globalObserver.observe(document.body, { childList: true, subtree: true });
			
			
		}
		/* if ($("#TB_title .zagrebweb-single-template-price").length == 0) {
			$("#TB_title #TB_ajaxWindowTitle").after(
				`<a class="zagrebweb-single-template-price" href="#"><span>599 EUR</span></a>
				<style>
				.zagrebweb-single-template-price {
				    background: transparent;
					color: #fff;
					padding: 10px 25px;
					border-radius: 30px;
					font-weight: 700;
					border: 0px solid #fff;
				}
				</style>
				`
			);
		} */
		if ($("#TB_title .zagrebweb-single-template-zagrebweb-logo").length == 0) {
			$("#TB_ajaxWindowTitle").contents().wrap("<span class='we-template-name'></span>");
			$("#TB_title #TB_ajaxWindowTitle").prepend(
				`<p class="zagrebweb-single-template-zagrebweb-logo" href="#">ZAGREBWEB</p>
				<style>
				.zagrebweb-single-template-zagrebweb-logo {
					color: #fff;
					padding: 5px 0px;
					font-weight: 700;
					margin: 0;
					font-size: 16px;
					line-height: 16px;
					font-style: italic;
				}
				</style>
				`
			);
		}

		//SHOW TITLE OPTIONS BAR
		$("#TB_title").css("opacity", "1");
	}

	const zagrebwebSingleTemplataCTAInterval = setInterval(
		renderWPPortfolioZagrebWebCTA,
		1000
	);

	//OPENS ORDER TEMPLATE MODAL
	$("body").on("click", ".zagrebweb-single-template-order-btn", function (e) {
		e.preventDefault();
		/* $("#zagrebweb-order-template-modal").modal({
			keyboard: false,
		}); */
		/* const myModal = new bootstrap.Modal("#zagrebweb-order-template-modal", {
			keyboard: false,
		}); */
		/* let myModal = new Modal(
			document.getElementById("zagrebweb-order-template-modal")
		);
		myModal.show(); */
	});

	// Sample array
	var weTemplatesData = JSON.parse(sessionStorage.getItem("weTemplatesData"));

	function getTemplateDataBySlug(slug) {
		if (!Array.isArray(weTemplatesData)) {
			console.error("weTemplatesData is not an array or not defined");
			return null;
		}

		for (let i = 0; i < weTemplatesData.length; i++) {
			if (weTemplatesData[i].post_slug === slug) {
				return weTemplatesData[i];
			}
		}
		return null;
	}

	const singleTemplate = getTemplateDataBySlug("spa-2");
	console.log("new tt: " + JSON.stringify(singleTemplate, null, 2));
	console.log(singleTemplate);

	// You can get url_string from window.location.href if you want to work with
	// the URL of the current page
	/* var url_string = window.location.href;
	var url = new URL(url_string);
	var c = url.searchParams.get("portfolio");
	console.log(c);

	// Get the Array item which matchs the id "2"
	var currentChosenTemplate = weTemplatesData.find(
		(item) => item.post_slug === c
	);

	console.log(currentChosenTemplate.post_id);

	function queryParamExistUrl(param = "") {
		if (new URLSearchParams(window.location.search).get(param) != null)
			return true;
		return false;
	}
	console.log(queryParamExistUrl("portfolio")); */

	//WE STICKY SIDEBAR
	function weStickyFilterSidebar() {
		//WRAP SIDEBAR ALL CHILD ELEMENTS
		$(".astra-portfolio-filters > *").wrapAll(
			"<div class='zagrebweb-fixed-sidebar-inner' />"
		);
		const weFilterContinerWidth = $(".astra-portfolio-filters").width();
		$(".zagrebweb-fixed-sidebar-inner").css(
			"width",
			weFilterContinerWidth + "px"
		);
		$(window).on("scroll resize", function () {
			const wePortfolioContainer = $("#astra-portfolio");
			const weWindow = $(window);
			const weHeader = $("#masthead");
			const weHeaderHeight = $("#masthead").outerHeight();
			const weScrollOffset = weHeaderHeight + 10;
			const weFilterContinerWidth = $(".astra-portfolio-filters").width();

			if (wePortfolioContainer && wePortfolioContainer.offset()) {
				var wePortfolioContainerOffsetTop = wePortfolioContainer.offset().top;
			}
			var weWindowScrollTop = weWindow.scrollTop();

			const zagrebwebPortfolioFiltersContainer =
				document.getElementsByClassName("astra-portfolio-filters");

			var zagrebwebPortfolioFiltersContainerBottomPosition;
			if (
				zagrebwebPortfolioFiltersContainer &&
				zagrebwebPortfolioFiltersContainer[0]
			) {
				zagrebwebPortfolioFiltersContainerBottomPosition =
					zagrebwebPortfolioFiltersContainer[0].getBoundingClientRect().bottom;
			}

			const zagrebwebPortfolioFilters = document.getElementsByClassName(
				"zagrebweb-fixed-sidebar-inner"
			);

			let zagrebwebPortfolioFiltersTopPosition;

			if (zagrebwebPortfolioFilters && zagrebwebPortfolioFilters[0]) {
				zagrebwebPortfolioFiltersTopPosition =
					zagrebwebPortfolioFilters[0].getBoundingClientRect().top;
			}
			let zagrebwebPortfolioFiltersBottomPosition;

			if (zagrebwebPortfolioFilters && zagrebwebPortfolioFilters[0]) {
				zagrebwebPortfolioFiltersBottomPosition =
					zagrebwebPortfolioFilters[0].getBoundingClientRect().bottom;
			}

			if (weWindow.width() >= bp.md) {
				if (
					weWindowScrollTop + weScrollOffset >=
					wePortfolioContainerOffsetTop
				) {
					if (
						zagrebwebPortfolioFiltersContainerBottomPosition >
						zagrebwebPortfolioFiltersBottomPosition
					) {
						console.log("sidebar fixed");
						$(".zagrebweb-fixed-sidebar-inner").css({
							position: "fixed",
							top: weScrollOffset + "px",
							bottom: "auto",
							width: weFilterContinerWidth + "px",
						});
					} else {
						if (zagrebwebPortfolioFiltersTopPosition <= weScrollOffset) {
							console.log("sidebar absolute bottom");
							$(".zagrebweb-fixed-sidebar-inner").css({
								position: "absolute",
								top: "auto",
								bottom: "0px",
								width: weFilterContinerWidth + "px",
							});
						} else {
							$(".zagrebweb-fixed-sidebar-inner").css({
								position: "fixed",
								top: weScrollOffset + "px",
								bottom: "auto",
								width: weFilterContinerWidth + "px",
							});
						}
					}
				} else {
					$(".zagrebweb-fixed-sidebar-inner").css({
						position: "relative",
						top: "auto",
						width: "100%",
					});
				}
			} else {
				//LESS THEN 768px
				$(".zagrebweb-fixed-sidebar-inner").css({
					position: "relative",
					top: "auto",
					bottom: "auto",
					width: "100%",
				});
			}
		});
	}
	//setInterval(weStickyFilterSidebar, 1000);
	weStickyFilterSidebar();

	//TRANSLATIONS
	function translateStringWpPortfolio() {
		$(".view-demo").text("Pregledaj");
	}
	translateStringWpPortfolio();

	//SCROLL TOP ON CATEGORY CLICK
	function scrollTopOnCategoryClick() {
		const wePortfolioContainer = $("#astra-portfolio");
		var wePortfolioContainerOffsetTop = wePortfolioContainer.offset().top;

		$("body").on("click", ".filter-links a", function (e) {
			console.log("nnttest: " + wePortfolioContainerOffsetTop);
			$("html, body").animate(
				{
					scrollTop: wePortfolioContainerOffsetTop,
				},
				1000
			);
		});
	}
	//scrollTopOnCategoryClick();

	//CLICK TO CATEGORY FROM GET

	/* const clickCategoryFromURLParameter = setInterval(
		weClickCategoryFromURLVar,
		500
	); */

	function weClickCategoryFromURLVar() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const weCategory = urlParams.get("category");

		console.log(weCategory);

		if (!$("a." + weCategory).hasClass("we-clicked-from-url-parameter")) {
			$("a." + weCategory)[0].click();
			$("a." + weCategory).addClass("we-clicked-from-url-parameter");
			let url = new URL("https://example.com?foo=1&bar=2&foo=3");
			let params = new URLSearchParams(url.search);

			// Delete the category parameter.
			var currentURL = window.location.href;
			var urlWithoutCategoryParameter = currentURL.replace(
				"?category=" + weCategory,
				""
			);
			var urlWithoutCategoryParameterWithoutDomainNameAndProtocol =
				urlWithoutCategoryParameter.replace("https://zagrebweb.hr/", "");
			window.history.replaceState(
				{},
				document.title,
				"/" + urlWithoutCategoryParameterWithoutDomainNameAndProtocol
			);
		} else {
			clearInterval(clickCategoryFromURLParameter);
			$("a." + weCategory).removeClass("we-clicked-from-url-parameter");
		}
	}
	//END CLICK TO CATEGORY FROM GET


	//MOBILE NAV
	var wePortfolioMobileNavMenuOpenerHTML = `
	<div class="we-mobile-nav-opener d-flex">
	<div class="we-mobile-nav-opener__text d-none">Kategorije</div>
	<div class="we-mobile-nav-opener__ham">
	<nav class="d-flex justify-content-end navigational-icons">
                        <div class="navigational-icon panel-switcher hamburger hamburger-1 d-lg-none" role="button">
<div class="navigational-icon__inner panel-switcher__inner hamburger__inner hamburger-1__inner">
    <div class="hamburger__line hamburger__line-1 hamburger__line-before hamburger-1__line hamburger-1__line-1 hamburger-1__line-before"></div>
    <div class="hamburger__line hamburger__line-2 hamburger__line-middle hamburger-1__line hamburger-1__line-2 hamburger-1__line-middle"></div>
    <div class="hamburger__line hamburger__line-3 hamburger__line-after hamburger-1__line hamburger-1__line-3 hamburger-1__line-after"></div>
</div> <!-- .panel-switcher__inner.hamburger-1__inner -->
</div> <!-- .panel-switcher.hamburger-1 -->                    </nav>
	</div>

	
	`
	$(".filters-wrap").prepend(wePortfolioMobileNavMenuOpenerHTML);


	//END WP PORTFOLIO
});
