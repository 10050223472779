

import "./_asides.scss";

$(function () {
    /* var sidebar = new StickySidebar('.aside', {
        containerSelector: '.section-main',
        innerWrapperSelector: '.aside__inner',
        topSpacing: 120,
        bottomSpacing: 20
    }); */
});