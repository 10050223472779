import "./_headers.scss";

$(function () {
	function checkIsHeaderFixedAndSetSpaces() {
		var wpAdminBarHeight = 0;
		var pageHeader = $("#masthead");
		if ($("body").hasClass("admin-bar")) {
			wpAdminBarHeight = $("#wpadminbar").height();
			pageHeader.css("margin-top", wpAdminBarHeight + "px");
		}
		if (pageHeader.hasClass("isFixed")) {
			var mastHeadHeight = pageHeader.outerHeight(true);
			/* $(".site-content").css(
				"margin-top",
				mastHeadHeight - wpAdminBarHeight + "px"
			); */
		}
	}

	checkIsHeaderFixedAndSetSpaces();

	$(window).on("resize", checkIsHeaderFixedAndSetSpaces);
});

import "./header-1/header-1";
