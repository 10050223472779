import "./_we-nav-panel-1.scss";
import { gsap } from 'gsap';
/* 
JS DEPENDECIES: GSAP
*/
const pageHeader = $("#masthead");
var navPanelId = ".we-nav-panel-1, .we-panel__inner";
var headerHeight = pageHeader.outerHeight();
var screenHeigth = $(window).innerHeight();
var avilableSpaceBelowHeader = screenHeigth - headerHeight;

//OPENING ANIM INIT
var navPanelOpeningAnim = gsap.timeline({
	repeat: 0,
	repeatDelay: 1,
	paused: true,
});
//OPENING ANIM SET
navPanelOpeningAnim.set("body", {
	overflow: "hidden",
});

navPanelOpeningAnim.set(navPanelId, {
	display: "block",
	height: avilableSpaceBelowHeader,
	top: headerHeight,
});
navPanelOpeningAnim.fromTo(
	navPanelId,
	{
		height: "0px",
	},
	{
		duration: 0.4,
		height: (avilableSpaceBelowHeader),
	}
);
navPanelOpeningAnim.set(".we-panel", {
	height: "100vh",
});
//END OPENING ANIM SET

export function weNavPanel1Open() {
	//PANEL SWITCHER SHOULD CALL THIS FUNCTION
	navPanelOpeningAnim.play();
}
export function weNavPanel1Close() {
	//PANEL SWITCHER SHOULD CALL THIS FUNCTION
	navPanelOpeningAnim.reverse();
}

$(function () {
	$(window).resize(function () {
		headerHeight = pageHeader.outerHeight();
		screenHeigth = $(window).innerHeight();
		avilableSpaceBelowHeader = screenHeigth - headerHeight;

		$(".panel-opened, .we-panel__inner").css("height", (avilableSpaceBelowHeader)+"px");
	});
});
