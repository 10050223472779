import './_cookie-notice.scss';

$(function () {
	$("#we-cookie-notice__agree-btn").on('click', function (e) {
		e.preventDefault();
		var CookieDate = new Date();
		CookieDate.setFullYear(CookieDate.getFullYear() + 1);
		document.cookie =
			"we" +
			"_user_accept_cookies=true; expires=" +
			CookieDate.toGMTString() +
			"; path=/" +
			";";
        $(".we-cookie-notice").fadeOut(200, function () {
            $(".we-cookie-notice").remove();
        });
	});

});