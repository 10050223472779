import "./_wp-core-widgets.scss";
import "./archives/widget-archives";
import "./audio/widget-audio";
import "./calendar/widget-calendar";
import "./categories/widget-categories";
import "./custom-html/widget-custom-html";
import "./gallery/widget-gallery";
import "./meta/widget-meta";
import "./navigation-menu/widget-navigation-menu";
import "./pages/widget-pages";
import "./recent-comments/widget-recent-comments";
import "./recent-posts/widget-recent-posts";
import "./search/widget-search";
import "./text/widget-text";
import "./video/widget-video";
