import "slick-carousel";
import 'slick-carousel/slick/slick.scss';
//import 'slick-carousel/slick/slick-theme.scss';

//SLICK SLIDER INIT
export const sliderSlick = () => {
	if ($(".slider-slick").length > 0) {
		$(".slider-slick").slick({
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 3,
			centerMode: false, //
			arrows: true,
			nextArrow: '<button type="button" class="slick-next slick-arrow"></button>',
			prevArrow: '<button type="button" class="slick-prev slick-arrow"></button>',
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						arrows: true,
						dots: false,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						arrows: true,
						dots: false,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						arrows: true,
						dots: false,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						arrows: true,
						dots: false,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						variableWidth: false,
						arrows: true,
						dots: false,
						slidesToScroll: 1,
					},
				},
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
		});
	}
}

//TESTIMONIAL SLIDER
export const testimonialSliderSlick = () => {
	if ($(".we-testimonials__slider").length > 0) {
		$(".we-testimonials__slider").slick({
			dots: true,
			infinite: false,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 2,
			//centerPadding: '100px',
			//centerMode: true, //
			arrows: true,
			appendDots: $(".slide-m-dots"),
			prevArrow: $(".slide-prev"),
			nextArrow: $(".slide-next"),
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 2,
						arrows: true,
						dots: true,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
						arrows: true,
						dots: true,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						arrows: true,
						dots: true,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						adaptiveHeight: false,
						arrows: true,
						dots: true,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						centerMode: false, //
						adaptiveHeight: false,
						//variableWidth: false,
						arrows: true,
						dots: true,
						slidesToScroll: 1,
					},
				},
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
		});
		//Implementing navigation of slides using mouse scroll
		/* $(".we-testimonials__slider").on("wheel", function (e) {
			e.preventDefault();
			if (e.originalEvent.deltaY < 0) {
				$(this).slick("slickPrev");
			} else {
				$(this).slick("slickNext");
			}
		}); */

		//CUSTOM MOUSE LOOK
		
	}
}
$(function () {
	sliderSlick();
	testimonialSliderSlick();
});

