import * as FilePond from "filepond";
//import { create, registerPlugin } from "filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond/dist/filepond.min.css";
import "parsleyjs";
import Swal from "sweetalert2";
import "bootstrap/js/dist/modal";

document.addEventListener("DOMContentLoaded", function () {
	console.log("before regster plugins");

	FilePond.registerPlugin(
		FilePondPluginFileEncode,
		FilePondPluginImagePreview,
		FilePondPluginImageExifOrientation,
		FilePondPluginFileValidateSize,
		//FilePondPluginImageEdit
		FilePondPluginFilePoster
	);

	console.log("before create pond");
	const pondContactForm = FilePond.create(
		document.querySelector("#contact-form-upload")
	);

	console.log("after create pond");
	console.log(pondContactForm);

	pondContactForm.setOptions({
		credits: false,
		allowReorder: false,
		instantUpload: false,
		styleItemPanelAspectRatio: 0.8,
		/* labelFileProcessingComplete: (file) => {
			if (file.fileType === "application/pdf") {
				return `<div class='filepond--file-preview-box pdf'><img src='https://img.icons8.com/?size=1x&id=kuOHmVeRoVF5&format=png' alt='PDF' class='filepond--file-preview-image' /></div>`;
			}

			// For other file types, you can return a different markup or just the default one
			return `<div class='filepond--file-preview-box image'>${file.getFileEncodeDataURL()}</div>`;
		}, */
		onaddfile: (error, fileItem) => {
			if (error) {
				console.error("An error occurred during file add", error);
				return;
			}
			handleFileAdd(fileItem);
		},
		labelIdle: `Povucite i ispustite svoje datoteke ili <span class=\"filepond--label-action\">Pretražite.</span> Max. 6 datoteka. Max. veličina datoteke 100MB`,
		labelFileBrowse: "",
		server: {
			process: async (
				fieldName,
				file,
				metadata,
				load,
				error,
				progress,
				abort
			) => {
				//GET NAME OF THE SENDER
				let contactName = document.querySelector(
					"#new-task-inquiry-form__contact-name"
				).value;
				// Fetch the signed URL from your server
				const response = await fetch(
					`https://zagrebweb.hr/wp-content/themes/zagrebweb/inc/zagrebweb-filepond-google-cloud-upload/zagrebweb-filepond-google-cloud-upload-handler.php?name=${encodeURIComponent(
						file.name
					)}&type=${encodeURIComponent(
						file.type
					)}&contactName=${encodeURIComponent(contactName)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				if (!response.ok) {
					error("Error fetching signed URL");
					return;
				}

				// Parse the JSON response
				const data = await response.json();

				// Check if a signedUrl is in the response
				if (!data.signedUrl) {
					error("No signed URL in the response");
					return;
				}

				const url = data.signedUrl;

				// Create a new XMLHttpRequest
				const xhr = new XMLHttpRequest();

				// Configure the request
				xhr.open("PUT", url, true);

				// Set up the event listeners
				xhr.upload.onprogress = (e) => {
					// Update the progress
					progress(e.lengthComputable, e.loaded, e.total);
				};
				xhr.onload = () => {
					// Done!
					load(xhr.responseText);
				};
				xhr.onerror = () => {
					// Error!
					error("Upload error");
				};

				// Send the request with the file and the additional field value
				/* let formData = new FormData();
				formData.append("file", file);
				formData.append("contactName", contactName);
				xhr.send(formData); */

				// Send the request with the file
				xhr.send(file);

				// Return an abort method
				return {
					abort: () => {
						// Abort the request
						xhr.abort();

						// Call the error method
						error("Aborted");
					},
				};
			},
		},
	});

	function handleFileAdd(fileItem) {
		if (!fileItem.fileType.startsWith('image') && !fileItem.fileType.startsWith('video')) {
			// The file is not an image or a video, so we add the custom icon
			fileItem.setMetadata('poster', 'https://zagrebweb.hr/assets/folder.png');
			//fileItem.setMetadata('poster', 'https://zagrebweb.hr/assets/file-storage.png');
		}
	}

	/* pondContactForm.setOptions({
		server: {
			process: async (
				fieldName,
				file,
				metadata,
				load,
				error,
				progress,
				abort
			) => {
				// Fetch the signed URL from your server
				const response = await fetch(
					`signurl.php?name=${encodeURIComponent(
						file.name
					)}&type=${encodeURIComponent(file.type)}`
				);
				const response = await fetch(
					`https://zagrebweb.hr/wp-content/themes/zagrebweb/inc/zagrebweb-filepond-google-cloud-upload/zagrebweb-filepond-google-cloud-upload-handler.php?name=${encodeURIComponent(
						file.name
					)}&type=${encodeURIComponent(file.type)}`
				);
				//const url = await response.text();

				const jsonResponse = await response.json();
				const url = jsonResponse.signedUrl;

				// Create a new XMLHttpRequest
				const xhr = new XMLHttpRequest();

				// Configure the request
				xhr.open("PUT", url, true);

				// Set up the event listeners
				xhr.upload.onprogress = (e) => {
					// Update the progress
					progress(e.lengthComputable, e.loaded, e.total);
				};
				xhr.onload = () => {
					// Done!
					load(xhr.responseText);
				};
				xhr.onerror = () => {
					// Error!
					error("oh no");
				};

				// Send the request with the file
				xhr.send(file);

				// Return an abort method
				return {
					abort: () => {
						// Abort the request
						xhr.abort();

						// Call the error method
						error("Aborted");
					},
				};
			},
		},
	}); */

	/* pondContactForm.setOptions({
		maxFileSize: "10MB",
		server: {
			process: async (
				fieldName,
				file,
				metadata,
				load,
				error,
				progress,
				abort
			) => {
				if (!file.type.startsWith("image/")) {
					error("Invalid file type");
					return;
				}

				const response = await fetch(
					`signurl.php?name=${encodeURIComponent(
						file.name
					)}&type=${encodeURIComponent(file.type)}`,
					{
						headers: {
							Authorization: "your-auth-token",
						},
					}
				);

				if (!response.ok) {
					error("Could not get signed URL");
					return;
				}

				const url = await response.text();
				const xhr = new XMLHttpRequest();
				xhr.open("PUT", url, true);
				xhr.upload.onprogress = (e) =>
					progress(e.lengthComputable, e.loaded, e.total);
				xhr.onload = () => load(xhr.responseText);
				xhr.onerror = () => error("oh no");

				xhr.send(file);

				return {
					abort: () => {
						xhr.abort();
						error("Aborted");
					},
				};
			},
		},
	}); */

	var formParseyElements = $("#new-task-inquiry-form-modal input, #new-task-inquiry-form-modal textarea");

	formParseyElements.parsley();

	//HANDLE FORM SUBMISSION

	$("#we-services-btn-submit").on("click", function (e) {
		e.preventDefault();

		var isValid = true;
		formParseyElements.each(function () {
			if ($(this).parsley().validate() !== true) isValid = false;
		});
		if (isValid) {
			
			//PROGRESS ANIMATION
			$("#we-service-request-modal .modal-content").append(
				'<div class="we-loading"><div class="we-loading__image-container"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:0 0" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><path fill="none" stroke="#1565C0" stroke-width="6.4" stroke-dasharray="42.765 42.765" d="M19.44 24C9.12 24 4 34.64 4 40s5.12 16 15.44 16c15.44 0 25.68-32 41.12-32C70.88 24 76 34.64 76 40s-5.12 16-15.44 16c-15.44 0-25.68-32-41.12-32z" stroke-linecap="round" style="transform-origin:50px 50px"><animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"/></path></svg></div></div>'
			);

			//FILEPOND FILES

			var weFilesData = [];

			var fileProcessingPromises = [];

			pondContactForm.getFiles().forEach(function (file) {
				fileProcessingPromises.push(
					new Promise((resolve, reject) => {
						pondContactForm
							.processFile(file.id)
							.then(() => {
								resolve(file);
							})
							.catch((error) => {
								console.log("Error during upload: ", error);
								resolve(
									"Error during file upload. This may not be uploaded. Error details: " +
										error
								);
							});
					})
				);
			});

			Promise.all(fileProcessingPromises).then((results) => {
				results.forEach((file) => {
					weFilesData.push(file);
				});

				pondContactForm.removeFiles();

				//END FILEPOND FILES

				//FORM FIELDS PROCESSING
				/* var inquirySubject = $(
				"#new-task-inquiry-form__category-list :selected"
			).text(); */
				var inquirySubject = $("#new-task-inquiry-form__description-subject-label").text();
				var inquiryMessage = $("#new-task-inquiry-form__description").val();

				//FILES DATA
				var fileNames = weFilesData.map((item) => item.filename);
				var fileNamesString = fileNames.toString();

				var inquiryPersonNameLastName = $(
					"#new-task-inquiry-form__contact-name"
				).val();
				var inquiryCompanyName = $(
					"#new-task-inquiry-form__company-name"
				).val();
				var inquiryEmail = $("#new-task-inquiry-form__email").val();

				//AJAX CALL
				$.ajax({
					url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
					type: "POST",
					//processData: true,
					/* contentType: false, */
					dataType: "html",
					//contentType: 'text/html',
					data: {
						action: "we_process_service_request_form",
						security: ajax_object.ajax_nonce, //from nonce in functions php
						inquiry_subject: inquirySubject,
						inquiry_message: inquiryMessage,
						inquiry_person_name_last_name: inquiryPersonNameLastName,
						inquiry_company_name: inquiryCompanyName,
						inquiry_email: inquiryEmail,
						uploaded_file_names: fileNamesString,
					},
					//data: fd,
					success: function (data) {
						var response = JSON.parse(data);

						$(".we-loading").remove();
						$('#we-service-request-modal').modal('hide');
						$(".modal-backdrop").remove();
						/* let myModalEl = document.getElementById('we-service-request-modal');
						let modal = bootstrap.Modal.getInstance(myModalEl); // Returns a Bootstrap modal instance
						modal.hide(); */
						Swal.fire({
							icon: "success",
							//html: "<p class='sweet-alert-message'>Čestitamo! Odgovorili ste točno na sva pitanja.</p>",
							title: "Hvala!",
  							text: "Vaša poruka je poslana. Kontaktirati ćemo Vas u unutar 48h :)",
							showConfirmButton: true,
							//timer: 5000,
							//willClose: we_reload,
						});

						if (window.history.replaceState) {
							window.history.replaceState(null, null, window.location.href);
						}
					},
					error: function (data) {
						//var results = JSON.parse(data);
						console.log("ajax error");
						$(".we-loading").remove();
						$('#we-service-request-modal').modal('hide');
						$(".modal-backdrop").remove();
						/* let myModalEl = document.getElementById('we-service-request-modal');
						let modal = bootstrap.Modal.getInstance(myModalEl); // Returns a Bootstrap modal instance */
						//modal.hide();
						Swal.fire({
							icon: "error",
							title: "Ups Ops!",
  							html: '<p>Dogodila se tehnička greška. Poruka nije poslana. Kontaktirajte nas direktno na info@zagrebweb.hr</p>',
							showConfirmButton: true,
							willClose: we_reload,
						});
						//$(".zagrebweb-logistar-inventory__status-message").text("Error!");

						if (window.history.replaceState) {
							window.history.replaceState(null, null, window.location.href);
						}
					},
				});
			});
		}
	});
});

