import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/scrollTrigger.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

//SPECIAL EFFECTS MODULES
//import './special-effects/hero-psd-to-html-box-hover';

//import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollTrigger);
//gsap.registerPlugin(ScrollToPlugin);

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context("./special-effects/", true, /\.js$/));

//HERO ANIMATION
let resetHeroNonFormContent = () => {
	gsap.set(".hero__header-title", { x: 50, opacity: 0 });
	gsap.set(".hero__wp-services-wordpress", { x: 50, opacity: 0 });
	gsap.set(".hero__wp-services-woocommerce", { x: 50, opacity: 0 });
	gsap.set(".hero__design-to-code-container", { x: -50, opacity: 0 });
};

ScrollTrigger.create({
	trigger: $(".hero"),
	start: "bottom top",
	onEnter: (self) => resetHeroNonFormContent(),
});

let animateHeroNonFormContent = () => {
	var heroNonFormContentAnimation = gsap.timeline({
		/* scrollTrigger: {
			trigger: $(".hero"),
			start: "center 85%",
		}, */
		repeat: 0,
		repeatDelay: 1,
	});
	heroNonFormContentAnimation.to($(".hero__header-title"), {
		duration: 0.825,
		delay: 1,
		ease: "Power0.easeNone",
		x: 0,
		opacity: 1,
	});
	heroNonFormContentAnimation.to($(".hero__wp-services-wordpress"), {
		duration: 0.625,
		ease: "Power0.easeNone",
		x: 0,
		opacity: 1,
	});
	heroNonFormContentAnimation.to($(".hero__wp-services-woocommerce"), {
		duration: 0.625,
		ease: "Power0.easeNone",
		x: 0,
		opacity: 1,
	});
	heroNonFormContentAnimation.to($(".hero__design-to-code-container"), {
		duration: 0.825,
		ease: "Power0.easeNone",
		x: 0,
		opacity: 1,
	});
}
let animateHeroForm = () => {
	var heroFormAnimation = gsap.timeline({
		/* scrollTrigger: {
			trigger: $(".hero"),
			start: "center 85%",
		}, */
		repeat: 0,
		repeatDelay: 1,
	});
	heroFormAnimation.to($(".hero__new-inquiry-wraper"), {
		duration: 0.825,
		//delay: 3,
		ease: "Power0.easeNone",
		x: 0,
		opacity: 1,
	});
	
}

ScrollTrigger.create({
	trigger: $(".hero"),
	start: "center 85%",
	onEnter: (self) => {animateHeroNonFormContent(); animateHeroForm()},
	onEnterBack: (self) => {animateHeroNonFormContent()},
});
//animateHeroNonFormContent();

//END HERO ANIMATION

//ANIMATED SERVICES
$(".we-services-animated .wp-block-webevolvement-blocks-bs-row").each(function (
	index,
	element
) {
	// element == this
    var animatedServiceAppearAnimation = gsap.timeline({
			scrollTrigger: {
				trigger: $(element),
				start: "center 75%",
			},
			repeat: 0,
			repeatDelay: 1,
		});
		animatedServiceAppearAnimation.to($(element).find(".we-service").first(), {
			duration: .9,
            ease: "circ.out",
			x: 0,
			opacity: 1,
		});
		animatedServiceAppearAnimation.to($(element).find(".we-service").last(), {
			duration: .9,
            ease: "circ.out",
			x: 0,
			opacity: 1,
		},0.15);


        ScrollTrigger.create({
					trigger: $(element),
					start: "top 75%",
					onToggle: (self) => console.log("toggled, isActive:", self.isActive),
				});
});
    /* var animatedServiceAppearAnimation = gsap.timeline({
			scrollTrigger: {
				trigger: ".we-service-1",
				start: "top 75%",
			},
			repeat: 0,
			repeatDelay: 1,
		});
		animatedServiceAppearAnimation.to(".we-service-1", {
			duration: .5,
            ease: "back.out(1.7)",
			//x: 0,
			opacity: 1,
		}); */
//END ANIMATED SERVICES

//ANIMATED TESTIMONIAL
var animatedTestimonialsAppearAnimation = gsap.timeline({
	scrollTrigger: {
		trigger: $('.we-testimonials'),
		start: "center 90%",
	},
	repeat: 0,
	repeatDelay: 1,
});
animatedTestimonialsAppearAnimation.to($(".we-testimonials"), {
	duration: 0.3,
	ease: "Power0.easeNone",
	//x: 0,
	opacity: 1,
	scale: 1,
});
/* animatedTestimonialsAppearAnimation.to($(".we-testimonials__single-testimonial-content"), {
	duration: 0.3,
	ease: "Power0.easeNone",
	y: '-50%',
	opacity: 1,
	scale: 1,
}, 0.5); */

//TESTIMONIAL HOVER
$(".we-testimonials__slide").hover(
	function () {
		// over
		let self = $(this);
		gsap.to(self, { duration: 0.3, borderColor: "rgb(79, 142, 189)" });
		gsap.to(self.find('.we-testimonials__single-testimonial-content-testimonial-text'), { duration: 0.3, color: "#333" });
	},
	function () {
		// out
		let self = $(this);
		gsap.to(self, { duration: 0.3, borderColor: "transparent" });
		gsap.to(self.find('.we-testimonials__single-testimonial-content-testimonial-text'), { duration: 0.3, color: "#8d8d8d" });
	}
);

//ANIMATED TESTIMONIAL

//IZRADA WEB STRANICA PAGE EFFECTS


//

$(function () {

	$(".we-abc-list .we-has-number-before--1").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#osmisljavanje-projekta", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--2").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#razvoj-sadrzaja-i-grafike", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--3").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#glavna-izvedba", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--4").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#testiranje", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--5").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#fin-tuniranje", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--6").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#postavljanje-na-server", offsetY: 120 },
		});
	});
	$(".we-abc-list .we-has-number-before--7").on("click", function (e) {
		gsap.to(window, {
			duration: .5,
			scrollTo: { y: "#pracenje-održavanje", offsetY: 120 },
		});
	});
	
});