//GET HEIGHEST ELEMENT
export function weGetHeighestElement(elementsClass, returningValue) {

    var allElementsHeights = [];
    $(elementsClass).each(function (index, element) {
        // element == this
        let elementHeight = Number($(element).outerHeight());
        allElementsHeights.push(elementHeight);
        
    });

    let heighestHeight = Math.max(...allElementsHeights);

    if (returningValue == "height") {
        return heighestHeight;
    } 
}