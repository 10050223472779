import './_hero-new-task-form.scss';
import Swal from "sweetalert2";
import "parsleyjs";
import Parsley from "parsleyjs";

$(document).ready(function () {

	//HERO FORM TABS
	$(".hero__new-inquiry-tab").on("click", function (e) {
		e.preventDefault();
		$(".hero__new-inquiry-tab").removeClass('active');
		$(this).addClass('active');
		$(".hero__tab-content").addClass("d-none");
		$("." + $(this).data("tab-content-class")).removeClass('d-none');
	});

	//.hero__tab-content


	//PARSLEY VALIDATION
	$("#new-task-inquiry-form").parsley();
	Parsley.addMessages("hr", {
		defaultMessage: "Neispravan unos.",
		type: {
			email: "Ovo polje treba sadržavati ispravnu email adresu.",
			url: "Ovo polje treba sadržavati ispravni url.",
			number: "Ovo polje treba sadržavati ispravno upisan broj.",
			integer: "Ovo polje treba sadržavati ispravno upisan cijeli broj.",
			digits: "Ovo polje treba sadržavati znamenke.",
			alphanum: "Ovo polje treba sadržavati brojke ili slova.",
		},
		notblank: "Ovo polje ne smije biti prazno.",
		required: "Ovo polje je obavezno.",
		pattern: "Neispravan unos.",
		min: "Vrijednost treba biti jednaka ili veća od %s.",
		max: "Vrijednost treba biti jednaka ili manja od %s.",
		range: "Vrijednost treba biti između %s i %s.",
		minlength: "Unos je prekratak. Treba sadržavati %s ili više znakova.",
		maxlength: "Unos je predugačak. Treba sadržavati %s ili manje znakova.",
		length:
			"Neispravna duljina unosa. Treba sadržavati između %s i %s znakova.",
		mincheck: "Treba odabrati najmanje %s izbora.",
		maxcheck: "Treba odabrati %s ili manje izbora.",
		check: "Treba odabrati između %s i %s izbora.",
		equalto: "Ova vrijednost treba biti ista.",
	});

	Parsley.setLocale("hr");

	const redirectToTasks = () => {
		window.location.href = "https://zagrebweb.hr/upiti/";
	};

	$("#new-task-inquiry-form").on("submit", function (event) {
		event.preventDefault();
		var fd = new FormData($("#new-task-inquiry-form")[0]);
		fd.append("action", "new_client_inquiry_2");

		var that = $(this),
			url = that.attr("action"),
			type = that.attr("method");
		var new_client_inquiry_task_name = $("#new-task-inquiry-form__title").val();
		var new_client_inquiry_task_description = $(
			"#new-task-inquiry-form__description"
		).val();
		var new_client_inquiry_client_name = $(
			"#new-task-inquiry-form__contact-name"
		).val();
		var new_client_inquiry_client_company_name = $(
			"#new-task-inquiry-form__company-name"
		).val();
		var new_client_inquiry_client_email = $(
			"#new-task-inquiry-form__email"
		).val();
		var new_client_inquiry_client_address_with_files = $(
			"#new-task-inquiry-form__source-files-address"
		).val();

		$("body").append(
			'<div class="we-loading" style="position: fixed; width: 100%; height: 100%; z-index: 999; background: #d7e3fa54; top: 0;"><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g transform="translate(50 50)"><g transform="scale(0.7)"><g transform="translate(-50 -50)"><g><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.7575757575757576s"></animateTransform><path fill-opacity="0.8" fill="#e15b64" d="M50 50L50 0A50 50 0 0 1 100 50Z"></path></g><g><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102s"></animateTransform><path fill-opacity="0.8" fill="#d9b1a6" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)"></path></g><g><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.5151515151515151s"></animateTransform><path fill-opacity="0.8" fill="#f8b26a" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)"></path></g><g><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="3.0303030303030303s"></animateTransform><path fill-opacity="0.8" fill="#a3ca8c" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)"></path></g</g></g></g></svg></div>"</div>'
		);

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			/* processData: false,
					contentType: false, */
			dataType: "text",
			data: {
				action: "new_client_inquiry",
				new_client_inquiry_task_name: new_client_inquiry_task_name,
				new_client_inquiry_task_description:
					new_client_inquiry_task_description,
				new_client_inquiry_client_name: new_client_inquiry_client_name,
				new_client_inquiry_client_company_name:
					new_client_inquiry_client_company_name,
				new_client_inquiry_client_email: new_client_inquiry_client_email,
				new_client_inquiry_client_address_with_files:
					new_client_inquiry_client_address_with_files,
			},
			//data: fd,
			success: function (data) {
				//var results = JSON.parse(data);
				console.log("ajax done succesful");
				console.log(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					timer: 1500,
					icon: "success",
					title: "Hvala! Vaš upit je poslan!",
					text: "",
					showConfirmButton: false,
					html:
						"<p>" +
						"" +
						"</p>" +
						'<div class="sweetalert-btns d-flex flex-column flex-sm-row justify-content-center">' +
						"</div>",
					willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				console.log("ajax error");
				console.log(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>Dogodila se neka greška. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
		$("#new-task-inquiry-form")[0].reset();
	});
	return false;
});
