import {
	pageHeader,
	pageMain,
	pageFooter,
} from "../../js-global/variables-constants/dom-elements";

import { windowHeight } from "../../js-global/variables-constants/visitor";
import "./_footers.scss";

$(function () {
	/* var pageBodyHeight =
		pageHeader.height() + pageMain.height() + pageFooter.height();
	var isFooterFixed;

	if (pageFooter.hasClass("isFixed")) {
		pageMain.css("margin-bottom", pageFooter.outerHeight(true) + "px");
		isFooterFixed = true;
	} else {
		if (pageBodyHeight < windowHeight) {
			pageFooter.addClass("isFixed");
		}
	}

	$(window).on('resize', function () {
		if (!isFooterFixed) {
			if (pageBodyHeight < windowHeight) {
				pageFooter.addClass("isFixed");
			} else {
				pageFooter.removeClass("isFixed");
			}
		}
	}); */
});

import "./footer-1/footer-1";
