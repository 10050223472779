    /* BREAKPOINTS */
    export const bp = {
        bp1: 360,
        //BOOTSTRAP BREAKPOINTS
        sm: 576,
        md: 768,
        lg: 992, 
        xl: 1200,
        //END BOOTSTRAP BREAKPOINTS
        
        bp2: 1600,
        bp3: 1920,
        bp4: 2400

        
        
    }
        //MEDUREZOLUCIJE
        bp.bp1_2 = (bp.bp1 + bp.sm)/2;
        bp.bp1_1 = (bp.bp1 + bp.bp1_2)/2;
        bp.bp1_3 = (bp.bp1_2 + bp.sm)/2;

        bp.sm_2 = (bp.sm + bp.md)/2;
        bp.sm_1 = (bp.sm + bp.sm_2)/2;
        bp.sm_3 = (bp.sm_2 + bp.md)/2;

        bp.md_2 = (bp.md + bp.lg)/2;
        bp.md_1 = (bp.md + bp.md_2)/2;
        bp.md_3 = (bp.md_2 + bp.lg)/2;

        bp.lg_2 = (bp.lg + bp.xl)/2;
        bp.lg_1 = (bp.lg + bp.lg_2)/2;
        bp.lg_3 = (bp.lg_2 + bp.xl)/2;

        bp.xl_2 = (bp.lg + bp.xl)/2;
        bp.xl_1 = (bp.lg + bp.lg_2)/2;
        bp.xl_3 = (bp.lg_2 + bp.xl)/2;

