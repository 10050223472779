import "./_google-map-1.scss";

const mapElement = document.getElementById("map");

if(mapElement) {
  let map;

  function initMap() {

  map = new google.maps.Map(mapElement, {
      center: { lat: -25.344, lng: 131.036 },
      zoom: 8,
  });

  // The marker, positioned at Uluru
  const marker = new google.maps.Marker({
      position: { lat: -25.344, lng: 131.036 },
      map: map,
    });

  }
}