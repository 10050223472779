import './_404-section.scss';
import { pageHeader } from '../../../../../../development/js-global/variables-constants/dom-elements';
import { pageFooter } from '../../../../../../development/js-global/variables-constants/dom-elements';


$(function () {
    function adjust404PageContent() {
			const page404Main = $(".error404 #site-content");
			let page404MainHeight =
				$(window).height() - pageHeader.height() - pageFooter.height();

			$(page404Main).css("height", page404MainHeight + "px");
		};

        adjust404PageContent();

        $(window).on('resize', function () { 
           adjust404PageContent(); 
        });

});