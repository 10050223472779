//import "bootstrap";
import { Modal } from "bootstrap";
import { bp } from "../../../../../development/js-global/variables-constants/breakpoints";
$(function () {
	//WP PORTFOLIO

	// URLs for the endpoints
	/* var servicesUrl = "https://zagrebweb.hr/wp-json/wp/v2/services/";
	var categoriesUrl = "https://zagrebweb.hr/wp-json/wp/v2/services-categories/";

	// Check if the data is in localStorage
	var services = JSON.parse(localStorage.getItem("services"));
	var categories = JSON.parse(localStorage.getItem("categories"));

	//if (services && categories) {
	if (1==0) {
		// If the data is in localStorage, use it
		processServicesAndCategories(services, categories);
	} else {
		// If the data is not in localStorage, fetch it
		$.when($.getJSON(servicesUrl), $.getJSON(categoriesUrl))
			.done(function (servicesResponse, categoriesResponse) {
				// The services and categories
				services = servicesResponse[0];
				categories = categoriesResponse[0];

				// Store the data in localStorage
				localStorage.setItem("services", JSON.stringify(services));
				localStorage.setItem("categories", JSON.stringify(categories));

				// Use the data
				processServicesAndCategories(services, categories);
			})
			.fail(function () {
				console.log("An error has occurred.");
			});
	} */

	function processServicesAndCategories(services, categories) {
		// Create a map of categories
		var categoryMap = {};
		$.each(categories, function (index, category) {
			categoryMap[category.id] = category.name;
		});

		// Create the optgroups and options
		var groups = {};

		// Add an "Upit" option at the beginning
		$("#new-task-inquiry-form__category-list").append(
			$("<option>", {
				value: "upit",
				text: "Upit",
			})
		);

		$.each(services, function (index, service) {
			var groupName = categoryMap[service["services-categories"]] || "Ostalo";
			if (!groups[groupName]) {
				groups[groupName] = $("<optgroup>", { label: groupName });
				$("#new-task-inquiry-form__category-list").append(groups[groupName]);
			}
			groups[groupName].append(
				$("<option>", {
					value: service.slug,
					text: service.title.rendered,
				})
			);
		});
	}

	//HANDLE OPENING OF MODAL ADDITIONS
	$('body').on('click', '.we-service-request-button, .zagrebweb-single-template-order-btn', function() {
		//var val = $(this).data("we-service-name");
		var serviceTitle = $(this).data("we-service-title");
		//$("#new-task-inquiry-form__category-list").val(val).prop("disabled", true);
		$("#new-task-inquiry-form__description-subject-label").html("<span class='new-task-inquiry-form__description-subject-label--predmet-underline'>Rješenje:</span> "+" "+serviceTitle);
	});


	

});
