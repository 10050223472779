import "./_navigation-primary-ls.scss";
import { gsap } from "gsap";

$(function () {

	//POSLOVNA RJESENJA
	var poslovnaRjesenjaSubMenuDepth1Open = gsap.timeline({repeat: 0, repeatDelay: 1, paused: true});
	
	//add 3 tweens that will play in direct succession.
	poslovnaRjesenjaSubMenuDepth1Open.set(".we-menu-poslovna-rjesenja .submenu-wraper-depth-1 .sub-menu", { display: 'block', height: '0px' });
	poslovnaRjesenjaSubMenuDepth1Open.to(".we-menu-poslovna-rjesenja .submenu-wraper-depth-1 .sub-menu", { duration: 0.2, height: 'auto', opacity: '1' });
	poslovnaRjesenjaSubMenuDepth1Open.to(".we-menu-poslovna-rjesenja .submenu-wraper-depth-1 .sub-menu > li", { duration: 0.2, top: '0px', opacity: '1' }, 0.2);

	//WP - WOO
	var wpWooSubMenuDepth1Open = gsap.timeline({repeat: 0, repeatDelay: 1, paused: true});
	
	//add 3 tweens that will play in direct succession.
	wpWooSubMenuDepth1Open.set(".we-menu-wp-woo .submenu-wraper-depth-1 .sub-menu", { display: 'block', height: '0px' });
	wpWooSubMenuDepth1Open.to(".we-menu-wp-woo .submenu-wraper-depth-1 .sub-menu", { duration: 0.2, height: 'auto', opacity: '1' });
	wpWooSubMenuDepth1Open.to(".we-menu-wp-woo .submenu-wraper-depth-1 .sub-menu > li", { duration: 0.2, top: '0px', opacity: '1' }, 0.2);

	//TEMATSKI
	var tematskiSubMenuDepth1Open = gsap.timeline({repeat: 0, repeatDelay: 1, paused: true});
	
	//add 3 tweens that will play in direct succession.
	tematskiSubMenuDepth1Open.set(".we-menu-tematski .submenu-wraper-depth-1 .sub-menu", { display: 'block', height: '0px' });
	tematskiSubMenuDepth1Open.to(".we-menu-tematski .submenu-wraper-depth-1 .sub-menu", { duration: 0.2, height: 'auto', opacity: '1' });
	tematskiSubMenuDepth1Open.to(".we-menu-tematski .submenu-wraper-depth-1 .sub-menu > li", { duration: 0.2, top: '0px', opacity: '1' }, 0.2);


	//SERVICES
	var primaryServicesSubMenuDepth1Open = gsap.timeline({repeat: 0, repeatDelay: 1, paused: true});
	
	//add 3 tweens that will play in direct succession.
	primaryServicesSubMenuDepth1Open.set(".we-services-item .submenu-wraper-depth-1 .sub-menu", { display: 'block', height: '0px' });
	primaryServicesSubMenuDepth1Open.to(".we-services-item .submenu-wraper-depth-1 .sub-menu", { duration: 0.2, height: 'auto', opacity: '1' });
	primaryServicesSubMenuDepth1Open.to(".we-services-item .submenu-wraper-depth-1 .sub-menu > li", { duration: 0.2, top: '0px', opacity: '1' }, 0.2);
	
	//AI
	var primaryPrateceUslugeSubMenuDepth1Open = gsap.timeline({repeat: 0, repeatDelay: 1, paused: true});
	
	//add 3 tweens that will play in direct succession.
	primaryPrateceUslugeSubMenuDepth1Open.set(".we-menu-pratece-usluge .submenu-wraper-depth-1 .sub-menu", { display: 'block', height: '0px' });
	primaryPrateceUslugeSubMenuDepth1Open.to(".we-menu-pratece-usluge .submenu-wraper-depth-1 .sub-menu", { duration: 0.2, height: 'auto', opacity: '1' });
	primaryPrateceUslugeSubMenuDepth1Open.to(".we-menu-pratece-usluge .submenu-wraper-depth-1 .sub-menu > li", { duration: 0.2, top: '0px', opacity: '1' }, 0.2);

	//PRIMARY LARGE SCREEN NAVIGATION
	if ($("#primary-large-screen-navigation").length > 0) {
		//HANDLE SUBMENUS

		//WORDPRESS SERVICES
		$("#primary-large-screen-navigation")
			.find(".we-menu-pratece-usluge.menu-item-has-children").not('.submenu-wraper .menu-item-has-children')
			.hover(
				function () {
					primaryPrateceUslugeSubMenuDepth1Open.play();
				},
				function () {
					primaryPrateceUslugeSubMenuDepth1Open.reverse();
				}
			);
		
		//SERVICES
		$("#primary-large-screen-navigation")
			.find(".we-services-item.menu-item-has-children").not('.submenu-wraper .menu-item-has-children')
			.hover(
				function () {
					primaryServicesSubMenuDepth1Open.play();
				},
				function () {
					primaryServicesSubMenuDepth1Open.reverse();
				}
			);

		//AI
		$("#primary-large-screen-navigation")
			.find(".we-menu-poslovna-rjesenja.menu-item-has-children").not('.submenu-wraper .menu-item-has-children')
			.hover(
				function () {
					poslovnaRjesenjaSubMenuDepth1Open.play();
				},
				function () {
					poslovnaRjesenjaSubMenuDepth1Open.reverse();
				}
			);
		//WP - WOO
		$("#primary-large-screen-navigation")
			.find(".we-menu-wp-woo.menu-item-has-children").not('.submenu-wraper .menu-item-has-children')
			.hover(
				function () {
					wpWooSubMenuDepth1Open.play();
				},
				function () {
					wpWooSubMenuDepth1Open.reverse();
				}
			);
		//TEMATSKI
		$("#primary-large-screen-navigation")
			.find(".we-menu-tematski.menu-item-has-children").not('.submenu-wraper .menu-item-has-children')
			.hover(
				function () {
					tematskiSubMenuDepth1Open.play();
				},
				function () {
					tematskiSubMenuDepth1Open.reverse();
				}
			);
		//END HANDLE SUBMENUS
	}
	//END PRIMARY LARGE SCREEN NAVIGATION
});