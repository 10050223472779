
import "./_widget-gallery.scss";

$(function () {
    var overlay = $('<div class="overlay-dark"></div>').appendTo('.gallery .gallery-item');

    $('.gallery-item').hover(function () {

        var self = $(this);
        
           gsap.to(self.find('overlay-dark'), {duration: 0.5, opacity: 1, scale: 1});
            
        }, function () {
            gsap.to(self.find('overlay-dark'), {duration: 0.5, opacity: 0, scale: 0.1});
        }
    );
});