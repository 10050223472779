import "./_user-icon-circle-name.scss";
import { gsap } from "gsap";

$(function () {

	var userMenuOpenCloseAnimation = gsap.timeline({ repeat: 0, repeatDelay: 1, paused:true });

	userMenuOpenCloseAnimation.set(
		".user-icon-circle-name__panel",
		{
			display: 'block',
			height: '0px',
		}
	);
	userMenuOpenCloseAnimation.to(".user-icon-circle-name__panel", {
		duration: 0.3,
		height: "auto",
	});


	var isPanelOpen = false;
    $(".user-icon-circle-name").hover(
			function () {
				isPanelOpen = true;
				userMenuOpenCloseAnimation.play();
			},
			function () { 
				// out
				userMenuOpenCloseAnimation.reverse();
				isPanelOpen = false;
			}
		);

    $(".user-icon-circle-name__link").click(function (e) {
			e.preventDefault();
		});
    
});