import "./_hamburgers.scss";
import {weNavPanel1Open} from '../../../we-panels/we-nav-panels/we-nav-panel-1/we-nav-panel-1';
import {weNavPanel1Close} from '../../../we-panels/we-nav-panels/we-nav-panel-1/we-nav-panel-1';
import {bp} from '../../../../../../../development/js-global/variables-constants/breakpoints';

$(function () {
	//HAM EVENTS
	$("body").on("click", ".hamburger", function (e) {
		e.preventDefault();

		if ($(this).hasClass("xfy") == false) {
			$(this).addClass("xfy");
			$(".panel-content").addClass("panel-opened");
			$(".we-panel__inner").addClass("panel-opened");
			weNavPanel1Open();
		} else {
			$(this).removeClass("xfy");
			$(".panel-content").removeClass("panel-opened");
			$(".we-panel__inner").removeClass("panel-opened");
			weNavPanel1Close();
		}
	});

	$(window).on("resize", function () {
		if (window.innerWidth >= bp.lg) {
			weNavPanel1Close();
			$(".hamburger").removeClass("xfy");
		}
	});
	//END HAM EVENTS
});

import "./ham-1/ham-1.es6";
