import "./scss-core/style.scss";

//DEV/SRC - JS GLOBAL
import { bp } from "./js-global/variables-constants/breakpoints";
//import "./js-global/variables-constants/dom-elements";


import "./js-global/functions/functions";

import "./js-global/variables-constants/visitor";
import "./js-global/site";

//WE STRAP - check. this way is not working
//import './lib/we-strap/we-strap';

//DEV/SRC - TEMPLATE PARTS
import "./template-parts/template-parts";

//THEME COMPONENTS
import "../wp-content/themes/zagrebweb/components/components.es6";

//THEME
import "../wp-content/themes/zagrebweb/theme.es6";

//SLIDERS INIT
import "./js-global/sliders-init/sliders-init";

//SPECIAL EFFECTS
import "./special-effects";

//FINALS
import "./finals";

//REACT APPS
//import "./react-apps/index";

//ADDITIONS
//WP PORTFOLIO
import "../wp-content/themes/zagrebweb/inc/zagrebweb-astra-portfolio/zagrebweb-astra-portfolio";
//ZAGREBWEB PREMIUM SEERVICES
import "../wp-content/themes/zagrebweb/inc/zagrebweb-premium-services/zagrebweb-premium-services";
//FILEPOND UPLOADS TO GOOGLE CLOUd AND FORM PROCESSING
import "../wp-content/themes/zagrebweb/inc/zagrebweb-premium-services/filepond-uploads-to-google-cloud";

//THEME SHORTCODES
import "../wp-content/themes/zagrebweb/inc/shortcodes/shortcodes";

