//BROWSER

//WINDOW
export var windowHeight;

$(function () {
	windowHeight = $(window).height();
	$(window).resize(function () {
		windowHeight = $(window).height();
	});
});
